import { render, staticRenderFns } from "./DisabledAdOverlay.vue?vue&type=template&id=81669d56&scoped=true&"
import script from "./DisabledAdOverlay.vue?vue&type=script&lang=ts&"
export * from "./DisabledAdOverlay.vue?vue&type=script&lang=ts&"
import style0 from "./DisabledAdOverlay.vue?vue&type=style&index=0&id=81669d56&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81669d56",
  null
  
)

export default component.exports