




















































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import { FacebookPage } from '@/shared/gen/messages.pisa';

@Component({
  mixins: [],
  components: {},
  props: {},
  validations: {},
})
export default class FacebookProfileDetails extends Vue {
  @Prop({ default: () => {} })
  syncedPage: FacebookPage;

  get hasSync() {
    return (this.syncedPage && this.syncedPage.pageId);
  }
}
