















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class DisabledAdOverlay extends Vue {
  @Prop({ default: false })
  disabled: boolean;
}
