



































































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import * as imgixFunctions from '@/shared/lib/imgixFunctions';
import InstagramProfileDetails from '@/shared/components/common/InstagramProfileDetails.vue';
import { FacebookPage } from '@/shared/gen/messages.pisa';

@Component({
  mixins: [],
  components: {
    InstagramProfileDetails,
  },
  props: {},
  validations: {},
})
export default class InstagramStoryCreative extends Vue {
  $refs!: {
    instagramStoryCreative: HTMLElement,
  };

  @Prop({ default: false })
  profileLocked: boolean;

  @Prop({ default: false })
  disabled: boolean;

  @Prop({ default: false, type: Boolean })
  hideAccountDetails: boolean;

  @Prop({ default: 'Your Instagram Page' })
  profileName: string;

  @Prop({ required: true })
  primaryText: string;

  @Prop({ default: 'LEARN_MORE' })
  callToAction: string;

  @Prop({ required: true })
  imageUrl: string;

  @Prop({ default: () => {} })
  colorPalette: imgixFunctions.ColorPalette;

  @Prop({ default: () => {} })
  syncedPage: FacebookPage;

  get buttonText() {
    if (this.callToAction === 'LISTEN_NOW') {
      return 'Listen Now';
    }
    return 'Learn More';
  }

  get creativeVibrantColorBackground() {
    if (this.colorPalette && this.colorPalette.vibrantDark) {
      return this.colorPalette.vibrantDark;
    }
    if (this.colorPalette && this.colorPalette.mutedDark) {
      return this.colorPalette.mutedDark;
    }
    if (this.colorPalette && this.colorPalette.muted) {
      return this.colorPalette.muted;
    }
    if (this.colorPalette && this.colorPalette.vibrant) {
      return this.colorPalette.vibrant;
    }
    return 'var(--color-primary-cta)';
  }

  get creativeVibrantColorText() {
    return 'var(--color-text-light)';
    // if (this.colorPalette && this.colorPalette.vibrantLight) {
    //   return this.colorPalette.vibrantLight;
    // }
    // if (this.colorPalette && this.colorPalette.mutedLight) {
    //   return this.colorPalette.mutedLight;
    // }
    // return 'var(--color-text-light)';
  }

  get blurredBackground() {
    return imgixFunctions.getBlurred(imgixFunctions.setBrightness(imgixFunctions.getImgixSizeQuerystring(this.imageUrl, 300, 536), -30));
  }

  emitLoaded() {
    this.$emit('loaded');
  }

  mounted() {
    this.$refs.instagramStoryCreative.addEventListener('click', this.handleClick);
  }

  beforeDestroy() {
    this.$refs.instagramStoryCreative.removeEventListener('click', this.handleClick);
  }

  handleClick(event: any) {
    event.preventDefault();
    this.$emit('adClicked');
  }
}
