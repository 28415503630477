












































































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import FacebookProfileDetails from '@/shared/components/common/FacebookProfileDetails.vue';
import { FacebookPage } from '@/shared/gen/messages.pisa';

@Component({
  mixins: [],
  components: {
    FacebookProfileDetails,
  },
  props: {},
  validations: {},
})
export default class FacebookNewsFeedCreative extends Vue {
  $refs!: {
    facebookNewsFeedCreative: HTMLElement,
  };

  @Prop({ default: false })
  profileLocked: boolean;

  @Prop({ default: false, type: Boolean })
  hideAccountDetails: boolean;

  @Prop({ default: false })
  disabled: boolean;

  @Prop({ default: 'Your Facebook Page' })
  profileName: string;

  @Prop({ required: true })
  primaryText: string;

  @Prop({ required: true })
  headline: string;

  @Prop({ default: 'NO_BUTTON' })
  callToAction: string;

  @Prop({ default: '' })
  imageUrl: string;

  @Prop({ required: true })
  caption: string;

  @Prop({ default: '' })
  description: string;

  @Prop({ default: () => {} })
  syncedPage: FacebookPage;

  get buttonText() {
    if (this.callToAction === 'LEARN_MORE') {
      return 'Learn More';
    }
    if (this.callToAction === 'LISTEN_NOW') {
      return 'Listen Now';
    }
    return '';
  }

  containsVariables(fieldA: string, fieldB: string, variable: string) {
    const regex = new RegExp(`\\b${variable}\\b`, 'gi');
    return (regex.test(fieldA) || regex.test(fieldB));
  }

  emitLoaded() {
    this.$emit('loaded');
  }

  mounted() {
    this.$refs.facebookNewsFeedCreative.addEventListener('click', this.handleClick);
  }

  beforeDestroy() {
    this.$refs.facebookNewsFeedCreative.removeEventListener('click', this.handleClick);
  }

  handleClick(event: any) {
    event.preventDefault();
    this.$emit('adClicked');
  }
}
