






































































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import * as imgixFunctions from '@/shared/lib/imgixFunctions';
import InstagramProfileDetails from '@/shared/components/common/InstagramProfileDetails.vue';
import { FacebookPage } from '@/shared/gen/messages.pisa';

@Component({
  mixins: [],
  components: {
    InstagramProfileDetails,
  },
  props: {},
  validations: {},
})
export default class InstagramFeedCreative extends Vue {
  $refs!: {
    instagramFeedCreative: HTMLElement,
  };

  @Prop({ default: false })
  profileLocked: boolean;

  @Prop({ default: false })
  disabled: boolean;

  @Prop({ default: false, type: Boolean })
  hideAccountDetails: boolean;

  @Prop({ default: 'Your Instagram Page' })
  profileName: string;

  @Prop({ default: '' })
  creative: string;

  @Prop({ required: true })
  primaryText: string;

  @Prop({ default: 'LEARN_MORE' })
  callToAction: string;

  @Prop({ required: true })
  imageUrl: string;

  @Prop({ default: () => {} })
  colorPalette: imgixFunctions.ColorPalette;

  @Prop({ default: () => {} })
  syncedPage: FacebookPage;

  get buttonText() {
    if (this.callToAction === 'LISTEN_NOW') {
      return 'Listen Now';
    }
    return 'Learn More';
  }

  get creativeVibrantColorBackground() {
    if (this.colorPalette && this.colorPalette.vibrant) {
      return `#${this.colorPalette.vibrant}`;
    }
    if (this.colorPalette && this.colorPalette.vibrantDark) {
      return `#${this.colorPalette.vibrantDark}`;
    }
    if (this.colorPalette && this.colorPalette.mutedDark) {
      return `#${this.colorPalette.mutedDark}`;
    }
    return 'var(--color-primary-cta)';
  }

  get creativeVibrantColorText() {
    let l = 0;
    if (this.colorPalette && this.colorPalette.vibrant) {
      l = this.RGBToL(this.colorPalette.vibrant);
    } else if (this.colorPalette && this.colorPalette.vibrantDark) {
      l = this.RGBToL(this.colorPalette.vibrantDark);
    } else if (this.colorPalette && this.colorPalette.mutedDark) {
      l = this.RGBToL(this.colorPalette.mutedDark);
    }
    if (l > 0.6) {
      return 'var(--color-black)';
    }
    return 'var(--color-text-light)';
  }

  RGBToL(hexcode: string) {
    const r = parseInt(hexcode.slice(1, 3), 16) / 255;
    const g = parseInt(hexcode.slice(3, 5), 16) / 255;
    const b = parseInt(hexcode.slice(5, 7), 16) / 255;
    // Find greatest and smallest channel values
    const cmin = Math.min(r, g, b);
    const cmax = Math.max(r, g, b);
    // const delta = cmax - cmin;
    // let h = 0;
    // let s = 0;
    let l = 0;
    // Calculate hue
    // No difference
    // if (delta === 0) {
    //   h = 0;
    //   // Red is max
    // } else if (cmax === r) {
    //   h = ((g - b) / delta) % 6;
    //   // Green is max
    // } else if (cmax === g) {
    //   h = (b - r) / delta + 2;
    //   // Blue is max
    // } else {
    //   h = (r - g) / delta + 4;
    // }
    // h = Math.round(h * 60);

    // Make negative hues positive behind 360°
    // if (h < 0) {
    //   h += 360;
    // }
    // Calculate lightness
    l = (cmax + cmin) / 2;

    // Calculate saturation
    // s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

    // Multiply l and s by 100
    // s = +(s * 100).toFixed(1);
    // l = +(l * 100).toFixed(1);

    return l;
  }

  emitLoaded() {
    this.$emit('loaded');
  }

  mounted() {
    this.$refs.instagramFeedCreative.addEventListener('click', this.handleClick);
  }

  beforeDestroy() {
    this.$refs.instagramFeedCreative.removeEventListener('click', this.handleClick);
  }

  handleClick(event: any) {
    event.preventDefault();
    this.$emit('adClicked');
  }
}
