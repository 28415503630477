
































































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import { FacebookPage } from '@/shared/gen/messages.pisa';

@Component({
  mixins: [],
  components: {},
  props: {},
  validations: {},
})
export default class InstagramProfileDetails extends Vue {
  @Prop({ default: () => {} })
  syncedPage: FacebookPage;

  get hasSync() {
    return (this.syncedPage && this.syncedPage.pageId);
  }

  @Prop({ default: 'feed' })
  displayType: string;

  get username() {
    if (this.syncedPage && this.syncedPage.name) {
      if (this.syncedPage.instagram && this.syncedPage.instagram.username) {
        return this.syncedPage.instagram.username;
      }
      return this.syncedPage.name;
    }
    return 'Your Instagram Page';
  }

  get profilePic() {
    if (this.syncedPage && this.syncedPage.pictureUrl) {
      if (this.syncedPage.instagram && this.syncedPage.instagram.pictureUrl) {
        return this.syncedPage.instagram.pictureUrl;
      }
      return this.syncedPage.pictureUrl;
    }
    return 'Your Instagram Page';
  }
}
