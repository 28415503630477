





























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class LoadingBackground extends Vue {
  @Prop({ default: 'Loading...' })
  readonly message: string;

  @Prop({ default: 'loaded' })
  readonly event: string;

  @Prop({ default: null })
  loadedOverride: boolean | null;

  animateBackground: boolean = true;

  loaded: boolean = false;

  get loadedFinal() {
    if (this.loadedOverride !== null) {
      return this.loadedOverride;
    }
    return this.loaded;
  }

  mounted() {
    const defaultSlot = this.$slots.default;
    if (defaultSlot) {
      defaultSlot.forEach((component) => {
        if (component.componentInstance) {
          component.componentInstance.$on(this.event, this.setLoaded);
        }
        if (component.elm && component.tag && component.tag === 'img') {
          component.elm.addEventListener(this.event, this.setLoaded);
        }
      });
    }
  }

  beforeDestroy() {
    const defaultSlot = this.$slots.default;
    if (defaultSlot) {
      defaultSlot.forEach((component) => {
        if (component.componentInstance) {
          component.componentInstance.$off(this.event, this.setLoaded);
        }
        if (component.elm && component.tag && component.tag === 'img') {
          component.elm.removeEventListener(this.event, this.setLoaded);
        }
      });
    }
  }

  setLoaded() {
    this.loaded = true;
    this.$emit('loaded');
    setTimeout(() => {
      this.animateBackground = false;
    }, 1500);
  }
}
