




















import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { API_ROOT } from '@/shared/lib/api';

@Component({
  mixins: [],
  components: {},
  props: {},
  validations: {},
})
export default class Creative extends Vue {
  $refs!: {
    iframe: HTMLIFrameElement,
  };

  @Prop({ default: '' })
  creative: string;

  @Prop({ default: () => {} })
  override: any;

  @Prop({ default: '' })
  idSalt: string;

  @Prop({ default: false, type: Boolean })
  overrideContent: boolean;

  @Watch('override')
  watchChanges() {
    if (this.overrideContent) {
      const data = { id: this.id, command: 'zire:updateCreative', ...this.override };
      this.$store.dispatch('creatives/postMessage', data);
    }
  }

  get id() {
    let id = `creative:${this.creative}`;
    if (this.idSalt) {
      id += this.idSalt;
    } else if (this.overrideContent) {
      id += 'oc';
    }
    return `${id}`;
  }

  get creativeEndpoint() {
    return `${API_ROOT}/creative/render?id=${this.creative}`;
  }

  mounted() {
    this.$store.dispatch('creatives/add', { id: this.id, iframe: this.$refs.iframe });
    this.$refs.iframe.onload = () => {
      this.$store.dispatch('creatives/postMessage', { id: this.id, command: 'zire:initialize' });
      if (this.overrideContent) {
        this.watchChanges();
      }
      this.$emit('loaded');
    };
    window.addEventListener('message', this.handleMessage);
  }

  beforeDestroy() {
    window.removeEventListener('message', this.handleMessage);
    this.$store.dispatch('creatives/remove', this.id);
  }

  handleMessage(event: any) {
    if (event.source === this.$refs.iframe.contentWindow) {
      const eventData = { id: this.id };
      switch (event.data.event) {
        case 'zire:focus':
          this.$store.dispatch('creatives/focused', this.id);
          this.$emit('focus', eventData);
          break;
        case 'zire:blur':
          this.$emit('blur', eventData);
          break;
        case 'zire:click':
          this.$emit('adClicked', Object.assign(eventData, { retailer: event.data.retailer }));
          break;
        case 'zire:player:play':
          this.$emit('adPlayed', eventData);
          break;
        default:
          // No-op...
      }
    }
  }
}
